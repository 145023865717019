<template>
  <div class="paypal-main">
    <div
      ref="paypal"
      class="paypal-wrapper"
    />
  </div>
</template>

<script>
// /* eslint-disable */
// import { addScript } from '@/utils'
// const PaypalUrl = 'https://www.paypal.com/sdk/js?client-id=AdpaWeKLd1eM1foOeZ1zaEmM-7BpopT1UMKNCVG5yOMHGmSYVOvWKCf38qZdzrdvjP1j1E8MAnhST-t7&components=buttons&vault=true'
// addScript(PaypalUrl)
import {
  paypalOrderSuccess,
  paypalOrderFailed
} from '@/utils'
let paypalButtons
const envMap = { local: 'dev', dev: 'dev', pre: 'pre', prod: 'pro' }
const VUE_APP_PAYPAL_CLIENTID = process.env.VUE_APP_PAYPAL_CLIENTID

export default {
  name: 'PaypalIndex',
  metaInfo: {
    script: [
      { src: `https://www.paypal.com/sdk/js?client-id=${VUE_APP_PAYPAL_CLIENTID}&components=buttons&vault=true` }
    ]
  },
  data () {
    return {
      orderId: '',
      planId: '',
      amount: ''
    }
  },
  mounted () {
    setTimeout(() => {
      this.updateButtons()
    }, 500)
  },
  created () {
    const { orderId, planId, amount } = this.$route.query
    this.orderId = orderId
    this.planId = planId
    this.amount = amount
    if (['dev', 'sit'].includes(process.env.VUE_APP_ENVIRONMENT)) {
      console.log(this.$route.query)
      console.log(window.webkit)
    }
  },
  methods: {
    updateButtons () {
      const paypal = window.paypal
      if (paypalButtons && paypalButtons.close) {
        paypalButtons.close()
      }
      const { orderId, planId, amount } = this
      paypalButtons = paypal.Buttons({
        style: {
          layout: 'horizontal',
          color: 'gold',
          shape: 'rect',
          label: 'paypal'
        },
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: 'USD',
                  value: amount
                },
                custom_id: JSON.stringify({
                  orderId,
                  planId,
                  env: envMap[process.env.VUE_APP_ENVIRONMENT]
                })
              }
            ]
          })
        },
        onApprove: (data, actions) => {
          paypalOrderSuccess(data).then(res => {
            console.log(res)
          })
        },
        onError: (data) => {
          paypalOrderFailed(data)
        }
      })
      paypalButtons.render(this.$refs.paypal)
    }
  }
}
</script>

<style lang="less">
    .paypal-main{
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        .paypal-wrapper{
            width: 730px;
            display: flex;
            justify-content: space-around;
        }
    }
</style>
